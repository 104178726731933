/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import { isDesktop, isTablet } from 'react-device-detect'
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { EmployeeForm } from '../../components/EmployeeForm/EmployeeForm'
import Loader from '../../components/Loader/Loader'
import { useGetAllTeamsQuery } from '../../redux/team.endpoints'
import { useGetAllTribesQuery } from '../../redux/tribe.endpoints'
import { useCreateUserMutation } from '../../redux/user.endpoints'
import { CreateUserDto } from '../../types/User'
import { BackendError } from '../../types/Errors'
import { Engagement } from '../../types/Engagement'
import { useLazyGetAllEngagementsQuery } from '../../redux/engagement.endpoints'
import { useLazyGetAllExperienceLevelQuery, useLazyGetAllTechnologiesQuery } from '../../redux/techstack.endpoints'
interface Props {
  companyId: number
}

const CreateEmployee = ({ companyId }: Props) => {
  const navigate = useNavigate()

  const { data: tribes, isSuccess: tribesSuccess } = useGetAllTribesQuery({
    companyId,
    pageSize: 0,
    pageNumber: 0,
    sortBy: 'id',
    searchQuery: '',
    sortType: 'DESC',
  })
  const { data: teams, isSuccess: teamsSuccess } =
    useGetAllTeamsQuery(companyId)
  // const { data: teams, isLoading: teamsLoading } = useGetAllTeams(companyId)
  const [createUser, createUserResult] = useCreateUserMutation()
  const [engagements, setEngagements] = useState([] as Engagement[])
  const [getEngagements, { data, isSuccess: engagementsSuccess }] = useLazyGetAllEngagementsQuery()
  const [getAllExperienceLevel, getAllExperienceLevelResponse] = useLazyGetAllExperienceLevelQuery();
  const [getAllTechnologies, getAllTechnologiesResponse] = useLazyGetAllTechnologiesQuery();
  useEffect(() => {
    setEngagements(data || [])
  }, [data])

  useEffect(() => {
    getEngagements({
      companyId,
      pageSize: 0,
      pageNumber: 0,
      sortBy: 'id',
      searchQuery: '',
      sortType: 'DESC',
    })
  }, [])
  const handleCreateUser = async (args: CreateUserDto) => {
    createUser(args)
  }

  useEffect(() => {
    if (createUserResult && createUserResult.isError) {
      const createUserError = createUserResult.error as BackendError
      if (createUserError.data.error) {
        toast.error(createUserError.data.error, {
          toastId: 'create-company-error',
        })  
      } else {
        toast.error(`An error occurred when creating member.`, {
          toastId: 'create-user-error',
        })
      }
    } else if (createUserResult && createUserResult.isSuccess) {
      toast.success(`Member created successfully!`, {
        toastId: 'create-user-success',
      })
      navigate('/members')
    }
  }, [createUserResult])

  useEffect(() => {
    getAllExperienceLevel({
      pageSize: 0,
      pageNumber: 0,
      sortBy: 'id',
      sortType: 'DESC',
      search: '',
    });
    getAllTechnologies({
      pageSize: 0,
      pageNumber: 0,
      sortBy: 'id',
      sortType: 'DESC',
      search: '',
    });
  }, [])

  return (
    <>
      <Helmet title="Time Off - Create Member" />
      <main className="container-fluid scrollable">
        <div className={`row pt-3 ${isDesktop || isTablet ? 'h-100' : ''}`}>
          <div className="col-8 offset-2 d-flex flex-column align-items-center justify-content-center bg-lazuli-white pb-5">
            <p className="align-self-start font-30 fw-bold">Add New Member</p>
            {teamsSuccess && tribesSuccess ? (
              <EmployeeForm
                handleCreateSubmit={handleCreateUser}
                displayErrorBanner={
                  createUserResult && createUserResult.isError
                }
                loading={createUserResult && createUserResult.isLoading}
                companyId={companyId}
                tribes={tribes || []}
                teams={teams || []}
                engagements={engagements || []}
                technologyList={getAllTechnologiesResponse.data || []}
                experienceLevel={getAllExperienceLevelResponse.data || []}
              />
            ) : (
              <Loader />
            )}
          </div>
        </div>
      </main>
    </>
  )
}

export default CreateEmployee
