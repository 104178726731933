import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faClose,
  faSearch,
  faPlus,
  faPenToSquare,
  faArrowUpAZ,
  faArrowDownAZ,
} from '@fortawesome/free-solid-svg-icons'
import ModalContainer from '../../components/ModalContainer/ModalContainer'
import { AllGuildTypes, Role } from '../../types/Roles'
import {
  useCreateGuildMutation,
  useLazyGetAllRolesQuery,
  useUpdateGuildMutation,
} from '../../redux/role.endpoints'
import { toast } from 'react-toastify'
import { handleSort, sortObject } from '../../utils/general'

const GuildView = () => {
  const [_, setGuilds] = useState([] as Role[])
  const [sortedGuilds, setSortedGuilds] = useState([] as Role[])
  const [idGuild, setIdGuild] = useState(0)
  const [guildName, setGuildName] = useState('')
  const [guildSalary, setGuildSalary] = useState(0)
  const [guildType, setGuildType] = useState('')
  const [isModalOpen, setModalOpen] = useState(false)

  const [getGuilds, { data }] = useLazyGetAllRolesQuery()
  const [createGuild, createGuildResult] = useCreateGuildMutation()
  const [updateGuild, updateGuildResult] = useUpdateGuildMutation()
  const [updateGuildIsActive, setUpdateGuildIsActive] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [sortColumn, setSortColumn] = useState<string>('role')
  const [sortOrder, setSortOrder] = useState<string>('asc')

  const handleActionName = () => (updateGuildIsActive ? 'Update' : 'Add New')
  const handleButtonName = () => (updateGuildIsActive ? 'Update' : 'Save')
  const [sortOrderIcon, setSortOrderIcon] = useState({
    role: faArrowUpAZ,
    type: faArrowUpAZ,
    salary: faArrowUpAZ,
  })
  const openModal = (role: Role | undefined) => {
    if (role) {
      setIdGuild(role.id)
      setGuildName(role.role)
      setGuildSalary(role.salary)
      setGuildType(role.type)
      setUpdateGuildIsActive(true)
      setModalOpen(true)
    } else {
      resetValues()
      setUpdateGuildIsActive(false)
      setModalOpen(true)
    }
  }

  const handleSaveGuild = () => {
    if (updateGuildIsActive) {
      updateGuild({
        id: idGuild,
        role: guildName.trim(),
        salary: guildSalary,
        type: guildType,
      })
    } else {
      createGuild({
        role: guildName.trim(),
        salary: guildSalary,
        type: guildType,
      })
    }
  }

  const handleSearchChange = event => {
    setSearchValue(event.target.value)
  }

  const resetValues = () => {
    getGuilds()
    setModalOpen(false)
    setGuildName('')
    setGuildSalary(0)
    setGuildType('')
    setSearchValue('')
    setIdGuild(0)
    setUpdateGuildIsActive(false)
  }

  useEffect(() => {
    getGuilds()
  }, [])

  useEffect(() => {
    if (data) {
      const filteredGuilds = data
        .filter(guild =>
          guild.role.toLowerCase().includes(searchValue.toLowerCase()),
        )
        .sort((a, b) => a.role.localeCompare(b.role))
      setGuilds(filteredGuilds || [])
      setSortedGuilds(filteredGuilds ? [...filteredGuilds] : [])
    }
  }, [data])

  useEffect(() => {
    if (createGuildResult && createGuildResult.isSuccess) {
      resetValues()
    } else if (createGuildResult && createGuildResult.error) {
      const error: any = createGuildResult.error
      toast.error(
        `An error occurred when creating the guild: ${error.data.error}`,
        {
          toastId: 'create-guild-error',
        },
      )
    }
  }, [createGuildResult])

  useEffect(() => {
    if (updateGuildResult && updateGuildResult.isSuccess) {
      resetValues()
    } else if (updateGuildResult && updateGuildResult.error) {
      const error: any = updateGuildResult.error
      toast.error(
        `An error occurred when updating the guild: ${error.data.error}`,
        {
          toastId: 'update-guild-error',
        },
      )
    }
  }, [updateGuildResult])

  const handleSortBy = (sortBy: string) => {
    const { newSortColumn, newSortOrder } = handleSort(
      sortBy,
      sortColumn,
      sortOrder,
    )
    setSortColumn(newSortColumn)
    setSortOrder(newSortOrder)

    setSortOrderIcon(prevState => {
      return {
        ...prevState,
        [sortBy]: newSortOrder === 'asc' ? faArrowUpAZ : faArrowDownAZ,
      }
    })
  }

  useEffect(() => {
    const sortedData = sortObject(sortedGuilds, sortColumn, sortOrder)
    setSortedGuilds([...sortedData])
  }, [sortColumn, sortOrder])

  return (
    <div className="container my-4">
      <ModalContainer
        isModalOpen={isModalOpen}
        hideModal={() => setModalOpen(false)}
      >
        <div className="modal-body">
          <div
            onClick={() => setModalOpen(false)}
            className="text-end pe-auto"
            role="button"
          >
            <FontAwesomeIcon icon={faClose} />
          </div>

          <div className="modal-header">
            <h5 className="modal-title">{handleActionName() + ' Guild'}</h5>
          </div>
          <div className="d-grid" style={{ paddingTop: '1.5rem' }}>
            <div className="d-grid">
              <label className="my-2">Name</label>
              <input
                placeholder="Enter name here"
                className="form-control"
                type="text"
                name="guild-name"
                value={guildName}
                onChange={e => setGuildName(e.target.value)}
                maxLength={25}
              />
            </div>
            <div className="d-grid">
              <label className="my-2">Salary</label>
              <input
                placeholder="Enter a salary for guild here"
                className="form-control"
                type="number"
                name="guild-salary"
                value={guildSalary}
                onChange={e => setGuildSalary(Number(e.target.value))}
                maxLength={25}
              />
            </div>
            <div className="d-grid">
              <label className="my-2">Type</label>
              <select
                id="guild-type"
                name="GuildType"
                value={guildType}
                onChange={e => setGuildType(e.target.value)}
                className="form-control"
              >
                <option disabled selected value={''}>
                  {' '}
                  -- Select a type --{' '}
                </option>
                {Object.keys(AllGuildTypes).map((element, index) => {
                  return (
                    <option key={index} value={AllGuildTypes[element]}>
                      {AllGuildTypes[element]}
                    </option>
                  )
                })}
              </select>
            </div>
          </div>
          <div
            className="action-control text-center"
            style={{ paddingTop: '2.9rem' }}
          >
            <button
              onClick={() => {
                setGuildName(''), setGuildSalary(0), setModalOpen(false)
              }}
              className="btn btn-secondary me-2"
            >
              Cancel
            </button>
            <button
              className="btn btn-primary"
              onClick={() => handleSaveGuild()}
              disabled={
                guildName.length == 0 || guildSalary == 0 || guildType == ''
              }
            >
              {handleButtonName()}
            </button>
          </div>
        </div>
      </ModalContainer>

      <Helmet title="Time Off - Guilds" />
      <p className="fs-2 fw-bold">Manage Guilds</p>
      <div className="d-flex justify-content-between">
        <div>
          <label className="">Search</label>
          <div className="input-group mb-3 input-group-border">
            <input
              type="text"
              className="form-control border border-0 outline-0"
              placeholder="Search by Guild"
              aria-label="Search by Guild"
              aria-describedby="button-addon2"
              value={searchValue}
              onChange={handleSearchChange}
            />
            <span className="input-group-text border border-0 search">
              <FontAwesomeIcon icon={faSearch} />
            </span>
          </div>
        </div>
        <div className="d-flex align-items-center">
          <button
            className="btn btn-primary"
            onClick={() => openModal(undefined)}
          >
            <FontAwesomeIcon className="me-2" icon={faPlus} />
            Add New Guild
          </button>
        </div>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th>
              Guild
              <FontAwesomeIcon
                icon={sortOrderIcon.role}
                onClick={() => handleSortBy('role')}
              />
            </th>
            <th>
              Type
              <FontAwesomeIcon
                icon={sortOrderIcon.type}
                onClick={() => handleSortBy('type')}
              />
            </th>
            <th>
              Salary
              <FontAwesomeIcon
                icon={sortOrderIcon.salary}
                onClick={() => handleSortBy('salary')}
              />
            </th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          {sortedGuilds.map((guild, index) => {
            return (
              <tr
                className="pointer-cursor"
                onClick={() => openModal(guild)}
                key={index}
              >
                <td>{guild.role}</td>
                <td>{guild.type}</td>
                <td>{guild.salary}</td>
                <td className="text-end pe-5">
                  <FontAwesomeIcon icon={faPenToSquare} />
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
      {sortedGuilds.length === 0 && (
        <h3 className="text-center">No results found.</h3>
      )}
    </div>
  )
}

export default GuildView
