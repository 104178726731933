import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { User } from '../../types/User'
import { Tribe } from '../../types/Tribe'
import { useNavigate } from 'react-router'
import { useLazyGetAllUsersQuery } from '../../redux/user.endpoints'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faClose,
  faPenToSquare,
  faSearch,
  faArrowUpAZ,
  faArrowDownAZ,
} from '@fortawesome/free-solid-svg-icons'
import { parseTribes } from '../../types/Tribe'
import { useLazyGetAllRolesQuery } from '../../redux/role.endpoints'
import { Role } from '../../types/Roles'
import Select from 'react-select'
import { useLazyGetAllTribesQuery } from '../../redux/tribe.endpoints'
import { useLazyGetAllTeamsQuery } from '../../redux/team.endpoints'
import { SelectOption } from '../../types/SelectOption'
import { useLazyGetAllExperienceLevelQuery, useLazyGetAllTechnologiesQuery } from '../../redux/techstack.endpoints'

interface Props {
  companyId: number
}

const EmployeesView = ({ companyId }: Props) => {
  const navigate = useNavigate()
  const [employees, setEmployees] = useState([] as User[])
  const [searchQuery, setSearchQuery] = useState('')
  const [isSearch, setIsSearch] = useState(false)
  const [getAllRoles, getAllRolesResponse] = useLazyGetAllRolesQuery()
  const [tribes, setTribes] = useState([] as Tribe[])
  const [getTribes, { data: tribesData }] = useLazyGetAllTribesQuery()
  const [availableTeams, setAvailableTeams] = useState<SelectOption[]>([])
  const [getAllTeams, { data: teamsData }] = useLazyGetAllTeamsQuery()
  const [getUsers, { data }] = useLazyGetAllUsersQuery()
  const [guilds, setGuilds] = useState<Array<Role>>([])
  const [selectedTeam, setSelectedTeam] = useState<{
    value: string
    label: string
  } | null>(null)
  const [selectedStatus, setSelectedStatus] = useState<{
    value: string
    label: string
  } | null>(null)
  const [selectedGuild, setSelectedGuild] = useState<{
    value: string
    label: string
  } | null>(null)
  const [selectedTribe, setSelectedTribe] = useState<{
    value: string
    label: string
  } | null>(null)

  const handleSort = (column: string) => {
    if (column === sortColumn) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
      setSortOrderIcon(prevState => {
        return {
          ...prevState,
          [column]: sortOrder === 'asc' ? faArrowDownAZ : faArrowUpAZ,
        }
      })
    } else {
      setSortColumn(column)
      setSortOrder('asc')
      setSortOrderIcon(prevState => {
        return {
          ...prevState,
          [column]: faArrowUpAZ,
        }
      })
    }
  }
  const [sortColumn, setSortColumn] = useState<string>('name')
  const [sortOrder, setSortOrder] = useState<string>('asc')
  const [sortOrderIcon, setSortOrderIcon] = useState({
    name: faArrowUpAZ,
    status: faArrowUpAZ,
    guild: faArrowUpAZ,
    tribe: faArrowUpAZ,
    team: faArrowUpAZ,
  })

  useEffect(() => {
    setTribes(tribesData || [])
  }, [tribesData])

  useEffect(() => {
    if (teamsData) {
      const teamOptions = teamsData.map(team => ({
        value: team.id.toString(),
        label: team.name,
      }))
      setAvailableTeams(teamOptions)
    }
  }, [teamsData])

  useEffect(() => {
    getAllTeams(companyId)
  }, [getAllTeams, companyId])

  useEffect(() => {
    setEmployees(data || [])
  }, [data])

  useEffect(() => {
    getAllRoles();
  }, [])

  useEffect(() => {
    getUsers({
      companyId,
      pageSize: 0,
      pageNumber: 0,
      sortBy: 'id',
      searchQuery: '',
      sortType: 'DESC',
    })
  }, [])

  useEffect(() => {
    if (getAllRolesResponse && getAllRolesResponse.data) {
      setGuilds(getAllRolesResponse.data)
    }
  }, [getAllRolesResponse])

  useEffect(() => {
    getTribes({
      companyId,
      pageSize: 0,
      pageNumber: 0,
      sortBy: 'id',
      searchQuery: '',
      sortType: 'DESC',
    })
  }, [])

  useEffect(() => {
    setTribes(tribesData || [])
  }, [tribesData])

  const clearSearch = () => {
    getUsers({
      companyId,
      pageSize: 0,
      pageNumber: 0,
      sortBy: 'id',
      searchQuery: '',
      sortType: 'DESC',
    })
    setSearchQuery('')
    setIsSearch(false)
  }

  const handleSearch = () => {
    if (searchQuery) {
      getUsers({
        companyId,
        pageSize: 0,
        pageNumber: 0,
        sortBy: 'id',
        searchQuery,
        sortType: 'DESC',
      })
      setIsSearch(true)
    }
  }

  const getStatus = date => {
    if (!date) return 'Active'
    const today = new Date()
    const userEndDate = new Date(date)

    if (userEndDate < today) return 'Inactive'
    else return 'Active'
  }

  return (
    <div className="container my-4">
      <Helmet title="Time Off - Members" />
      <p className="fs-2 fw-bold">Manage Members</p>
      <div className="d-flex justify-content-between py-2 bottom-divider">
        <p className="h3 mb-0">Filters</p>
        <button
          className="btn btn-primary"
          onClick={() => navigate('/members/create')}
        >
          + Add New Member
        </button>
      </div>
      <div className="d-flex justify-content-between">
        <div style={{ width: '400px' }}>
          <label className="">Search</label>
          <div className="input-group mb-3 input-group-border outline-blue ">
            <input
              type="text"
              className="form-control border border-0 outline-0"
              placeholder="By any term"
              aria-label="By any term"
              aria-describedby="button-addon2"
              value={searchQuery}
              onChange={e => {
                const query = e.target.value.toLowerCase()
                setSearchQuery(query)
              }}
            />
            {isSearch ? (
              <span
                className="input-group-text border border-0 search"
                onClick={() => clearSearch()}
              >
                <FontAwesomeIcon icon={faClose} />
              </span>
            ) : (
              <span
                onClick={handleSearch}
                className="input-group-text border border-0 search"
              >
                <FontAwesomeIcon icon={faSearch} />
              </span>
            )}
          </div>
        </div>
        <div style={{ width: '200px' }}>
          <label className="">Status</label>
          <div className="d-flex flex-column">
            <Select
              options={[
                { value: 'active', label: 'Active' },
                { value: 'inactive', label: 'Inactive' },
              ]}
              placeholder="Filter by Status"
              value={selectedStatus}
              onChange={selectedOption => setSelectedStatus(selectedOption)}
              isClearable={true}
            />
          </div>
        </div>
        <div style={{ width: '200px' }}>
          <label className="">Guild</label>
          <div className="d-flex flex-column">
            <Select
              options={guilds.map(guild => ({
                value: guild.id.toString(),
                label: guild.role,
              }))}
              placeholder="Filter by Guild"
              value={selectedGuild}
              onChange={selectedOption => setSelectedGuild(selectedOption)}
              isClearable={true}
            />
          </div>
        </div>
        <div style={{ width: '200px' }}>
          <label className="">Tribe</label>
          <div className="d-flex flex-column">
            <Select
              options={tribes.map(tribe => ({
                value: tribe.id.toString(),
                label: tribe.name,
              }))}
              placeholder="Filter by Tribe"
              value={selectedTribe}
              onChange={selectedOption => setSelectedTribe(selectedOption)}
              isClearable={true}
            />
          </div>
        </div>
        <div style={{ width: '200px' }}>
          <label className="">Team</label>
          <div className="d-flex flex-column">
            <Select
              options={availableTeams}
              placeholder="Filter by Team"
              value={selectedTeam}
              onChange={selectedOption => setSelectedTeam(selectedOption)}
              isClearable={true}
            />
          </div>
        </div>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th>
              Name
              <span className="ms-2">
                <FontAwesomeIcon
                  icon={sortOrderIcon.name}
                  onClick={() => handleSort('name')}
                />
              </span>
            </th>
            <th>
              Status
              <span className="ms-2">
                <FontAwesomeIcon
                  icon={sortOrderIcon.status}
                  onClick={() => handleSort('status')}
                />
              </span>
            </th>
            <th>
              Guild
              <span className="ms-2">
                <FontAwesomeIcon
                  icon={sortOrderIcon.guild}
                  onClick={() => handleSort('guild')}
                />
              </span>
            </th>
            <th>
              Tribe
              <span className="ms-2">
                <FontAwesomeIcon
                  icon={sortOrderIcon.tribe}
                  onClick={() => handleSort('tribe')}
                />
              </span>
            </th>
            <th>Team</th>
            <th>{/*Actions*/}</th>
          </tr>
        </thead>
        <tbody>
          {[...employees]
            .filter(employee => {
              const fullName =
                `${employee.firstName} ${employee.lastName}`.toLowerCase()
              return fullName.includes(searchQuery)
            })
            .filter(employee => {
              if (selectedStatus && selectedStatus.value === 'active') {
                return getStatus(employee.end_on) === 'Active'
              } else if (
                selectedStatus &&
                selectedStatus.value === 'inactive'
              ) {
                return getStatus(employee.end_on) === 'Inactive'
              }
              return true
            })
            .filter(employee => {
              if (selectedGuild && selectedGuild.label) {
                return (
                  guilds.find(guild => guild.id === employee.guildId)?.role ===
                  selectedGuild.label
                )
              }
              return true
            })
            .filter(employee => {
              if (selectedTribe && selectedTribe.value) {
                return employee.tribes.some(
                  tribe => tribe.id.toString() === selectedTribe.value,
                )
              }
              return true
            })
            .filter(employee => {
              if (selectedTeam && selectedTeam.value) {
                return employee.teamAllocations?.some(
                  allocation =>
                    allocation.team?.id.toString() === selectedTeam.value,
                )
              }
              return true
            })
            .sort((a, b) => {
              let sortValueA
              let sortValueB
              if (sortColumn === 'name') {
                sortValueA = `${a.firstName} ${a.lastName}`
                sortValueB = `${b.firstName} ${b.lastName}`
              } else if (sortColumn === 'status') {
                sortValueA = getStatus(a.end_on)
                sortValueB = getStatus(b.end_on)
              } else if (sortColumn === 'guild') {
                sortValueA =
                  guilds.find(guild => guild.id === a.guildId)?.role ?? ''
                sortValueB =
                  guilds.find(guild => guild.id === b.guildId)?.role ?? ''
              } else if (sortColumn === 'tribe') {
                const aTribe = parseTribes(a.tribes)
                const bTribe = parseTribes(b.tribes)
                sortValueA = aTribe
                sortValueB = bTribe
              } else if (sortColumn === 'team') {
                const aTeams =
                  a.teamAllocations
                    ?.map(allocation => allocation.team?.name || '')
                    .filter(val => !!val)
                    .join(', ') || ''
                const bTeams =
                  b.teamAllocations
                    ?.map(allocation => allocation.team?.name || '')
                    .filter(val => !!val)
                    .join(', ') || ''
                sortValueA = aTeams
                sortValueB = bTeams
              }
              if (sortOrder === 'asc') {
                return sortValueA.localeCompare(sortValueB)
              } else {
                return sortValueB.localeCompare(sortValueA)
              }
            })
            .map((employee, index) => {
              const userTribes = parseTribes(employee.tribes)
              const userTeams =
                employee.teamAllocations
                  ?.map(allocation => allocation.team?.name || '')
                  .filter(val => !!val)
                  .join(', ') || ''
              return (
                <tr
                  className="pointer-cursor"
                  onClick={() => navigate(`/members/${employee.id}`)}
                  key={index}
                >
                  <td>
                    {employee.firstName} {employee.lastName}
                  </td>
                  <td>{getStatus(employee.end_on)}</td>
                  <td>
                    {guilds.find(guild => guild.id === employee.guildId)
                      ?.role ?? employee.guildId}
                  </td>
                  <td>{userTribes}</td>
                  <td>{userTeams}</td>
                  <td>
                    <FontAwesomeIcon icon={faPenToSquare} />
                  </td>
                </tr>
              )
            })}
        </tbody>
      </table>
      {employees.length === 0 && (
        <h2 className="text-center">No members found</h2>
      )}
    </div>
  )
}

export default EmployeesView
