import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import DatePicker from 'react-datepicker'
import { User } from '../../types/User'
import {
  useLazyGetAllUsersTimesOffReportQuery,
  useLazyGetAllUsersTribeOrgReportQuery,
} from '../../redux/timeoff.endpoints'
import { UsersTimesOff, UsersTribeOrg } from '../../types/TimeOff'
import Select from 'react-select'
import { useGetAllClientsQuery } from '../../redux/client.endpoints'
import { Client } from '../../types/Client'
import { useGetAllTribesQuery } from '../../redux/tribe.endpoints'
import { useGetAllTeamsQuery, useLazyGetSnapshotsQuery } from '../../redux/team.endpoints'
import ReactToPrint from 'react-to-print'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFile } from '@fortawesome/free-regular-svg-icons'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import ReportTable from '../../components/ReportTable/ReportTable'
import { toast } from 'react-toastify'
import { useGetAllEngagementsQuery } from '../../redux/engagement.endpoints'
import { SelectOption } from '../../types/SelectOption'
import { Col, Row } from 'react-bootstrap'
import { useLazyGetAllRolesQuery } from '../../redux/role.endpoints'
import { Role } from '../../types/Roles'
import moment from 'moment'
import { areDatesOnSameDay, getMondayDateFromWeek, getWeekOfYear, calculateMonthsDifference } from '../../utils/general'

interface Props {
  profile: User
  companyId: number
}

const TimeOffReport = ({ profile, companyId }: Props) => {
  const [usersTimesOff, setUsersTimesOff] = useState<UsersTimesOff[]>([])
  const [usersTribeOrg, setUsersTribeOrg] = useState<UsersTribeOrg[]>([])
  const [startDate, setStartDate] = useState<Date>()
  const [endDate, setEndDate] = useState<Date>()
  const [clients, setClients] = useState([] as Client[])
  const [availableClients, setAvailableClients] = useState([] as SelectOption[])
  const [availableTribes, setAvailableTribes] = useState([] as SelectOption[])
  const [availableTeams, setAvailableTeams] = useState([] as SelectOption[])
  const [selectedClient, setSelectedClient] = useState<SelectOption>()
  const [selectedTribe, setSelectedTribe] = useState<SelectOption>()
  const [selectedTeam, setSelectedTeam] = useState<SelectOption>()
  const [selectedReport, setSelectedReport] = useState<string>('')
  const [tableHeaders, setTableHeaders] = useState<Array<string>>([
    'Guild',
    'Team',
    'Time Off',
  ])
  const [disabledButton, setDisabledButton] = useState<boolean>(false)
  const [loadingTableInfo, setLoadingTableInfo] = useState<boolean>(false)
  const [showNoResultsLabel, setShowNoResultsLabel] = useState<boolean>(false)

  const today = new Date()
  const currentYear = today.getFullYear().toString()
  const [selectedYear, setSelectedYear] = useState<SelectOption>({value: currentYear, label: currentYear})
  const [selectedWeek, setSelectedWeek] = useState<SelectOption>({value: "", label: ""})
  const [selectedSnapshot, setSelectedSnapshot] = useState<SelectOption>({value: "", label: ""})
  const [snapshotDates, setSnapshotDates] = useState<SelectOption[]>([])
  const [getSnapshots, getSnapshotsResponse] = useLazyGetSnapshotsQuery()

  const [getAllRoles, getAllRolesResponse] = useLazyGetAllRolesQuery()
  const [guilds, setGuilds] = useState<Array<Role>>([])

  useEffect(() => {
    getAllRoles()
    getSnapshots(companyId)
  }, [])

  const formatDate = (date) => {
    return moment.utc(date).format('MMM D, YYYY');
  }

  useEffect(() => {
    if (getSnapshotsResponse && getSnapshotsResponse.data) {
      const dates = getSnapshotsResponse.data.map((snapshotDates) => moment.utc(snapshotDates.snapshotDate).toISOString())
      const setDates = new Set([...dates])

      const snapshotOptions: SelectOption[]  = Array.from(setDates)
      .sort(function(a, b){return new Date(b).getTime() - new Date(a).getTime()})
      .map((snapshotDates) => {
        return {
          value: snapshotDates,
          label: `${formatDate(snapshotDates)}`
        }}
      )
      setSnapshotDates(snapshotOptions)
    }
  }, [getSnapshotsResponse, selectedYear])

  const filteredDates = snapshotDates.filter(snapshotDate => {
    if(selectedYear.value === "") return false;
    const date = moment(snapshotDate.value);
    if (!date.isValid()) {
      return false;
    }
    return date.year() === parseInt(selectedYear.value);
  });

  const selectWeeks = filteredDates.map(snap => {
    const weekOfYear = getWeekOfYear(snap.value)
    return {label: `Week ${weekOfYear}`, value: `${weekOfYear}`}
  })

  const getTeamTribe = (team) => {
    return team ? team.tribeId : 0
  }

  useEffect(() => {
    cleanTableInfo()
    if(selectedWeek.value){
      const mondayDate = getMondayDateFromWeek(selectedWeek.value, selectedYear.value)

      const selectedSnapshot = {
        value: mondayDate,
        label: `${formatDate(mondayDate)}`
      }
      setSelectedSnapshot(selectedSnapshot)
    }else{
      setSelectedSnapshot({value: "", label: ""})
    }
  }, [selectedWeek])

  useEffect(() => {
    if (getAllRolesResponse && getAllRolesResponse.data) {
      setGuilds(getAllRolesResponse.data)
    }
  }, [getAllRolesResponse])

  const { data: teams } = useGetAllTeamsQuery(companyId)

  const [getUsersTimesOff, getUsersTimesOffResponse] =
    useLazyGetAllUsersTimesOffReportQuery()
  const [getAllUsersTribeOrgReport, getAllUsersTribeOrgReportResponse] =
    useLazyGetAllUsersTribeOrgReportQuery()
  const { data: clientData } = useGetAllClientsQuery(companyId.toString())
  const { data: tribes } = useGetAllTribesQuery({
    companyId,
    pageSize: 0,
    pageNumber: 0,
    sortBy: 'id',
    searchQuery: '',
    sortType: 'DESC',
  })
  const { data: engagements } = useGetAllEngagementsQuery({
    companyId,
    pageSize: 0,
    pageNumber: 0,
    sortBy: 'id',
    searchQuery: '',
    sortType: 'DESC',
  })

  const createReport = async () => {
    setShowNoResultsLabel(true)
    setLoadingTableInfo(true)
    if (selectedReport === 'Time Off') {
      await getUsersTimesOff({
        userId: profile.id.toString(),
        teamId: selectedTeam?.value ?? '',
        clientId: selectedClient?.value ?? '',
        tribeId: selectedTribe?.value ?? '',
        from: startDate ? startDate.getTime().toString() : '',
        to: endDate ? endDate.getTime().toString() : '',
      }).then(() => {
        setLoadingTableInfo(false)
      })
    }
    if (selectedReport === 'Tribe Org') {
      if (selectedTribe) {
        await getAllUsersTribeOrgReport({
          userId: profile.id.toString(),
          teamId: selectedTeam?.value ?? '',
          clientId: selectedClient?.value ?? '',
          tribeId: selectedTribe.value,
          snapshotDate: selectedSnapshot.value ? (new Date(selectedSnapshot.value)).getTime().toString() : ''
        }).then(() => {
          setLoadingTableInfo(false)
        })
      } else {
        toast.warning(`Please select a tribe before creating a report`, {
          toastId: 'create-report-tribe-warning',
        })
      }
    }
  }

  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]

  const yearOptions = [
    { value: '2023', label: '2023' },
    { value: '2022', label: '2022' },
  ]

  const calculateNextTimeOff = (timeoffs: any[]) => {
    if (timeoffs && timeoffs.length > 0) {
      const result = timeoffs
        .map(time => {
          const today = new Date()
          let colorClass = "purple-badge"

          const currentDate = moment();
          const startDate = moment(time.startDate);
          const endDate = moment(time.endDate);

          const months = calculateMonthsDifference(today.toISOString(), time.startDate);
          

          if(endDate.isBefore(currentDate)){
            colorClass = "gray-badge";
          }else if(months <= 2){
            colorClass = "green-badge"
          }else if(months <= 6){
            colorClass = "yellow-badge"
          }

          let text = `${moment(time.startDate).format('MMM DD, YYYY')} - ${moment(
            time.endDate,
          ).format('MMM DD, YYYY')}`

          if(startDate.isSame(endDate, 'day')) text = `${moment(time.startDate).format('MMM DD, YYYY')}`

          return {
            text,
            colorClass: `badge badge-pill me-1 ${colorClass}`
          }
        });

      return result
    }

    return []
  }

  const typeReportOptions = () => [
    { label: `Time Off`, value: `Time Off` },
    { label: `Tribe Org`, value: `Tribe Org` },
  ]

  const getTeamsToString = () => {
    const tempTeams = availableTeams.map(team => team.label)
    tempTeams.shift()
    return tempTeams
  }

  const dateValidation = () => {
    if (startDate === undefined && endDate === undefined) return false
    if (endDate === undefined) return true
    if (startDate === undefined) return true
    return false
  }

  const cleanSelectedItems = () => {
    setSelectedClient(undefined)
    setEndDate(undefined)
    setStartDate(undefined)
  }

  const cleanTableInfo = () => {
    setUsersTimesOff([])
    setUsersTribeOrg([])
  }

  const filterTeamByTribeAndClient = () => {
    let tribeTeamOptions: SelectOption[] = []
    if (selectedTribe && tribes) {
      const selected = tribes.find(
        item => item.id === parseInt(selectedTribe.value),
      )
      selected &&
        selected.teams.forEach(team => {
          tribeTeamOptions.push({ label: team.name, value: `${team.id}` })
        })
    }
    if (selectedClient && tribes && engagements && teams) {
      const selected = clients.find(
        item => item.id === parseInt(selectedClient.value),
      )
      if (selected) {
        const clientEngagements = engagements.filter(
          engagement => engagement.client.id === selected.id,
        )
        if (selectedTribe) {
          tribeTeamOptions = tribeTeamOptions.filter(item =>
            clientEngagements.find(
              engagement => `${engagement.team.id}` === item.value,
            ),
          )
        } else {
          teams
            .filter(team =>
              clientEngagements.find(
                engagement => engagement.team.id === team.id,
              ),
            )
            .forEach(team => {
              tribeTeamOptions.push({ label: team.name, value: `${team.id}` })
            })
        }
      }
    }
    const selectedTeamIsValid =
      selectedTeam?.value === '0' ||
      selectedTeam?.value === '' ||
      !!tribeTeamOptions.find(item => item.value === selectedTeam?.value)

    if (!selectedTeamIsValid) {
      setSelectedTeam(undefined)
    }
    return tribeTeamOptions.length > 0
      ? [
          { label: 'All Teams', value: '' },
          { label: 'Staff Augmentation Loan', value: '-1' },
          ...tribeTeamOptions,
        ]
      : []
  }

  const componentRef = useRef(null)

  useEffect(() => {
    const clientOptions: SelectOption[] = []
    if (selectedTribe && tribes && engagements && clients) {
      const selected = tribes.find(
        item => item.id === parseInt(selectedTribe.value),
      )
      if (selected) {
        const tribeEngagements = engagements.filter(
          engagement => engagement.team.tribeId === selected.id,
        )
        clients
          .filter(client =>
            tribeEngagements.find(
              engagement => engagement.client.id === client.id,
            ),
          )
          .forEach(client => {
            clientOptions.push({ label: client.name, value: `${client.id}` })
          })
      }
      setAvailableClients(clientOptions)
      return
    }
    if (clients) {
      clientOptions.push({ label: 'All Clients', value: '' })
      clients.forEach(client => {
        clientOptions.push({ label: client.name, value: `${client.id}` })
      })
      setAvailableClients(clientOptions)
      return
    }
    setAvailableClients([])
  }, [selectedTribe, teams, tribes, engagements, clients])

  useEffect(() => {
    const tribeOptions: SelectOption[] = [{ label: 'All', value: '0' }]
    if (selectedClient && tribes && clients && engagements) {
      const selected = clients.find(
        item => item.id === parseInt(selectedClient?.value),
      )
      if (selected) {
        const clientEngagements = engagements.filter(
          engagement => engagement.client.id === selected.id,
        )
        tribes
          .filter(tribe =>
            clientEngagements.find(
              engagement => engagement.team.tribeId === tribe.id,
            ),
          )
          .forEach(tribe => {
            tribeOptions.push({ label: tribe.name, value: `${tribe.id}` })
          })
      }
      setAvailableTribes(tribeOptions)
      return
    }
    if (tribes) {
      setAvailableTribes(
        tribeOptions.concat(
          tribes.map(tribe => {
            return { label: tribe.name, value: `${tribe.id}` }
          }),
        ),
      )
      return
    }
    setAvailableTribes([])
    return
  }, [selectedClient, teams, tribes, engagements, clients])

  useEffect(() => {
    if (selectedTribe || selectedClient) {
      setAvailableTeams(filterTeamByTribeAndClient())
      return
    }
    if (teams) {
      const TeamOptions = [
        { label: 'All Teams', value: '' },
        { label: 'Staff Augmentation Loan', value: '-1' },
      ]
      teams.forEach(team => {
        TeamOptions.push({ label: team.name, value: `${team.id}` })
      })
      setAvailableTeams(TeamOptions)
      return
    }
    setAvailableTeams([])
    return
  }, [selectedTribe, selectedClient, tribes, teams, engagements, clients])

  useEffect(() => {
    setClients(clientData || [])
  }, [clientData])

  useEffect(() => {
    if (getUsersTimesOffResponse && getUsersTimesOffResponse.data) {
      setUsersTribeOrg([])
      setUsersTimesOff(getUsersTimesOffResponse.data)
    }
  }, [getUsersTimesOffResponse])

  useEffect(() => {
    if (
      getAllUsersTribeOrgReportResponse &&
      getAllUsersTribeOrgReportResponse.data
    ) {
      setUsersTimesOff([])
      setUsersTribeOrg(
        getAllUsersTribeOrgReportResponse.data?.filter(user => {
          if (!user.end_on) return true
          const today = new Date()
          const userEndDate = new Date(user.end_on)
          if (userEndDate < today) return false
          else return true
        }),
      )
    }
  }, [getAllUsersTribeOrgReportResponse])

  useEffect(() => {
    cleanTableInfo()
    if (selectedReport === 'Time Off' || selectedReport === '') {
      setTableHeaders(['Guild', 'Tribe', 'Team', 'Time Off'])
      setDisabledButton(false)
    }
    if (selectedReport === 'Tribe Org') {
      cleanSelectedItems()
      setTableHeaders(['Status', 'Guild', 'Tribe', 'Team (Allocation)', 'Start Date'])
      setDisabledButton(true)
    }
  }, [selectedReport])

  useEffect(() => {
    cleanTableInfo()
  }, [selectedTribe])

  useEffect(() => {
    cleanTableInfo()
  }, [selectedTeam])

  useEffect(() => {
    if (showNoResultsLabel) {
      setShowNoResultsLabel(false)
    }
  }, [
    selectedTribe,
    selectedTeam,
    selectedReport,
    selectedClient,
    startDate,
    endDate,
  ])

  return (
    <>
      <Helmet title="Time Off - Reports" />
      <div className="px-5 my-5">
        <h1>Reports</h1>
      </div>
      <div className="px-5">
        <div className="d-flex justify-content-between py-2 bottom-divider">
          <p className="h3 mb-0">Create Reports</p>
          <button
            className="btn btn-primary"
            onClick={() => createReport()}
            disabled={dateValidation() || !selectedReport}
          >
            <FontAwesomeIcon icon={faFile} /> Create Report
          </button>
        </div>
        <div className="my-3">
          <Row>
            <Col xs={2}>
              <div className="d-flex flex-column">
                <label>Report Type</label>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  isClearable={true}
                  isRtl={false}
                  isSearchable={true}
                  name="Name"
                  options={typeReportOptions()}
                  onChange={option => {
                    setSelectedReport(option?.value || '')

                    if (option) {
                      setSelectedTribe({ label: 'All', value: '0' })
                      setSelectedTeam({ label: 'All Teams', value: '' })
                    } else {
                      setSelectedTribe(undefined)
                      setSelectedTeam(undefined)
                    }
                  }}
                />
              </div>
            </Col>
            <Col xs={2}>
              <div className="d-flex flex-column">
                <label>{selectedReport === 'Tribe Org' ? 'Year' : "From"}</label>
                {selectedReport !== 'Tribe Org' ? <DatePicker
                  disabled={disabledButton}
                  maxDate={endDate}
                  className={`form-control timeoff-input mx-1`}
                  placeholderText={'Select a date'}
                  isClearable
                  selected={startDate}
                  onChange={date => {
                    if (date) setStartDate(date)
                    else setStartDate(undefined)
                  }}
                /> : <Select
                  key={`year-select-${selectedYear}`}
                  className="basic-single"
                  isClearable={selectedYear.value !== ""}
                  isRtl={false}
                  value={selectedYear}
                  options={yearOptions}
                  onChange={option => {
                    if (option) setSelectedYear(option)
                    else setSelectedYear({value: "", label: ""})
                  }}
                  styles={{ menu: provided => ({ ...provided, zIndex: 9999 }) }}
                  placeholder={'2022'}
                />}
              </div>
            </Col>
            <Col xs={2}>
              <div className="d-flex flex-column">
                <label>{selectedReport === 'Tribe Org' ? 'Week' : "To"}</label>
                {selectedReport !== 'Tribe Org' ? <DatePicker
                  disabled={disabledButton}
                  minDate={startDate}
                  className={`form-control timeoff-input mx-1`}
                  placeholderText={'Select a date'}
                  isClearable
                  selected={endDate}
                  onChange={date => {
                    if (date) setEndDate(date)
                    else setEndDate(undefined)
                  }}
                /> : <Select
                  key={`month-select-${selectedWeek}`}
                  className="basic-single"
                  isClearable={selectedWeek.value !== ""}
                  isRtl={false}
                  value={selectedWeek}
                  options={selectWeeks}
                  onChange={option => {
                    if (option) setSelectedWeek(option)
                    else setSelectedWeek({value: "", label: ""})
                  }}
                  styles={{ menu: provided => ({ ...provided, zIndex: 9999 }) }}
                  placeholder={'Week 21'}
                />}
              </div>
            </Col>
            <Col xs={2}>
              <div className="d-flex flex-column">
                <label>{selectedReport === 'Tribe Org' ? 'Date' : "Client"}</label>
                {selectedReport !== 'Tribe Org' ?<Select
                  key={`client-select-${selectedClient}`}
                  isDisabled={disabledButton}
                  className="basic-single"
                  classNamePrefix="select"
                  isClearable={true}
                  isRtl={false}
                  isSearchable={true}
                  name="Client"
                  options={availableClients}
                  value={selectedClient}
                  onChange={option => {
                    if (option) setSelectedClient(option)
                    else setSelectedClient(undefined)
                  }}
                /> : <Select
                  key={`month-select-${selectedWeek}`}
                  className="basic-single"
                  isClearable={selectedSnapshot.value !== ""}
                  isRtl={false}
                  options={filteredDates}
                  styles={{ menu: provided => ({ ...provided, zIndex: 9999 }) }}
                  placeholder={'Pick a date range'}
                  value={selectedSnapshot}
                  onChange={option => {
                    if (option) setSelectedSnapshot(option)
                    else {
                      setSelectedWeek({value: "", label: ""})
                      setSelectedSnapshot({value: "", label: ""})
                    }
                  }}
                />}
              </div>
            </Col>
            <Col xs={2}>
              <div className="d-flex flex-column">
                <label>Tribe</label>
                <Select
                  key={`tribe-select-${selectedTribe}`}
                  className="basic-single"
                  isClearable={true}
                  isRtl={false}
                  isSearchable={true}
                  options={availableTribes}
                  value={selectedTribe}
                  onChange={option => {
                    if (option) setSelectedTribe(option)
                    else setSelectedTribe(undefined)

                    if (option?.value !== '0') {
                      setSelectedTeam(undefined)
                    }

                    if (option?.value === '0') {
                      setSelectedTeam({ label: 'All Teams', value: '' })
                    }
                  }}
                />
              </div>
            </Col>
            <Col xs={2}>
              <div className="d-flex flex-column">
                <label>Team</label>
                <Select
                  key={`team-select-${selectedTeam}`}
                  className="basic-single"
                  classNamePrefix="select"
                  isClearable={true}
                  isRtl={false}
                  isSearchable={true}
                  options={availableTeams}
                  value={selectedTeam}
                  onChange={option => {
                    if (option) setSelectedTeam(option)
                    else setSelectedTeam(undefined)
                  }}
                />
              </div>
            </Col>
          </Row>
        </div>
        <div ref={componentRef}>
          <div className="report-table">
            {usersTimesOff.length > 0 && !loadingTableInfo && (
              <ReportTable
                usersInfo={usersTimesOff}
                calculateNextTimeOff={calculateNextTimeOff}
                selectedReport={selectedReport}
                selectedTeam={selectedTeam?.value}
                tableHeaders={tableHeaders}
                guilds={guilds}
              />
            )}
            {usersTribeOrg.length > 0 && !loadingTableInfo && (
              <ReportTable
                usersInfo={usersTribeOrg}
                calculateNextTimeOff={calculateNextTimeOff}
                selectedReport={selectedReport}
                selectedTeam={
                  teams?.find(
                    team => team.id === parseInt(selectedTeam?.value ?? ''),
                  )?.name || getTeamsToString().join(', ')
                }
                tableHeaders={tableHeaders}
                guilds={guilds}
              />
            )}
          </div>
          {usersTimesOff.length === 0 &&
            usersTribeOrg.length === 0 &&
            showNoResultsLabel && (
              <h2 className="text-center">No members found</h2>
            )}
        </div>
      </div>
    </>
  )
}

export default TimeOffReport
