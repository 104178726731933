import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import ModalContainer from '../../components/ModalContainer/ModalContainer'
import { Client } from '../../types/Client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faClose,
  faPenToSquare,
  faPlus,
  faSortAlphaDown,
  faSortAlphaUp,
  faSortNumericDown,
  faSortNumericUp,
} from '@fortawesome/free-solid-svg-icons'
import {
  useCreateClientMutation,
  useGetAllClientsQuery,
} from '../../redux/client.endpoints'
import { User } from '../../types/User'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router'
import moment from 'moment-timezone'
interface Props {
  user: User
}

const ClientsView = ({ user }: Props) => {
  const [sortColumn, setSortColumn] = useState('name')
  const [sortOrder, setSortOrder] = useState('asc')
  const navigate = useNavigate()
  const [isModalOpen, openModal] = useState(false)
  const [client, setClients] = useState([] as Client[])
  const [newClientName, setNewClientName] = useState('')
  const [newClientDescription, setNewClientDescription] = useState('')
  const [isNewClientActive, setNewClientAsActive] = useState(true)
  const { data, isLoading } = useGetAllClientsQuery(user.company.id.toString())
  const [createClient, createClientResult] = useCreateClientMutation()

  const closeModal = () => {
    openModal(false)
    setNewClientAsActive(true)
    setNewClientDescription('')
    setNewClientName('')
  }

  useEffect(() => {
    setClients(
      data ? [...data].sort((a, b) => a.name.localeCompare(b.name)) : [],
    )
  }, [isLoading])

  const handleSort = (column: string) => {
    if (sortColumn === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
    } else {
      setSortColumn(column)
      setSortOrder('asc')
    }
    const sortedClients = [...client].sort((a, b) => {
      if (column === 'name') {
        return sortOrder === 'asc'
          ? a.name.localeCompare(b.name)
          : b.name.localeCompare(a.name)
      } else if (column === 'created_at') {
        return sortOrder === 'asc'
          ? moment(a.created_at).unix() - moment(b.created_at).unix()
          : moment(b.created_at).unix() - moment(a.created_at).unix()
      }
      return 0
    })
    setClients(sortedClients)
  }

  useEffect(() => {
    setClients(
      data ? [...data].sort((a, b) => a.name.localeCompare(b.name)) : [],
    )
  }, [isLoading])

  useEffect(() => {
    if (createClientResult && createClientResult.isSuccess) {
      setClients(client.concat(createClientResult.data))
    } else if (createClientResult && createClientResult.error) {
      toast.error(
        `An error occurred when creating the client: ${createClientResult.error}`,
        {
          toastId: 'create-client-error',
        },
      )
    }
    closeModal()
  }, [createClientResult])

  return (
    <div className="container my-4">
      <ModalContainer hideModal={() => closeModal()} isModalOpen={isModalOpen}>
        <div className="modal-body">
          <div
            onClick={() => closeModal()}
            className="text-end pe-auto"
            role="button"
          >
            <FontAwesomeIcon icon={faClose} />
          </div>
          <h2 className="text-center">Create New Client</h2>
          <div className="d-grid">
            <label className="my-2">
              Name
              <input
                className="d-block form-control timeoff-input"
                type="text"
                name="client-name"
                value={newClientName}
                onChange={e => setNewClientName(e.target.value)}
                maxLength={25}
              />
            </label>
            <label className="my-2">
              Description
              <input
                className="d-block form-control timeoff-input"
                type="text"
                name="client-description"
                value={newClientDescription}
                onChange={e => setNewClientDescription(e.target.value)}
              />
            </label>
            <label className="my-2">
              <input
                className="me-2"
                type="checkbox"
                checked={isNewClientActive}
                onChange={() => setNewClientAsActive(prev => !prev)}
              />
              Set client as active
            </label>
            <div className="action-control text-center">
              <button
                className="btn btn-secondary me-2"
                onClick={() => closeModal()}
              >
                Cancel
              </button>
              <button
                onClick={() =>
                  createClient({
                    name: newClientName,
                    isActive: isNewClientActive,
                    description: newClientDescription,
                    companyId: user.company.id,
                  })
                }
                className="btn btn-primary"
              >
                Create
              </button>
            </div>
          </div>
        </div>
      </ModalContainer>
      <Helmet title="Time Off - Clients" />
      <div className="d-flex justify-content-between mb-2">
        <p className="fs-2 fw-bold mb-0">All Clients</p>
        <button
          className="btn btn-primary py-0"
          onClick={() => openModal(true)}
        >
          <FontAwesomeIcon className="me-2" icon={faPlus} /> Add new client
        </button>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th>#</th>
            <th>
              Name
              <FontAwesomeIcon
                icon={sortOrder === 'asc' ? faSortAlphaDown : faSortAlphaUp}
                onClick={() => handleSort('name')}
                className={sortColumn === 'name' ? 'active' : ''}
              />
            </th>
            <th>
              Date added
              <FontAwesomeIcon
                icon={sortOrder === 'asc' ? faSortNumericDown : faSortNumericUp}
                onClick={() => handleSort('created_at')}
                className={sortColumn === 'created_at' ? 'active' : ''}
              />
            </th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          {client.map((client, index) => {
            return (
              <tr
                className="pointer-cursor"
                onClick={() => navigate(`/clients/${client.id}`)}
                key={index}
              >
                <td>{index + 1}</td>
                <td>{client.name}</td>
                <td>{moment(client.created_at).format('MM/DD/YYYY')}</td>
                <td className="text-end pe-5">
                  <FontAwesomeIcon icon={faPenToSquare} />
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
      {client.length == 0 && <h2 className="text-center">No client found</h2>}
    </div>
  )
}

export default ClientsView
